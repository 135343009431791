<template>
  <div v-if="!loading">
    <div class="d-flex justify-content-center align-items-center align-self-md-center">
      <img
        alt="Payment Gateway"
        :src="idScan"
      >
      <h3
        v-if="title"
        class="text-center font-weight-bolder mb-1 ml-1"
      >
        {{ $t(title) }}
      </h3>
    </div>
    <validation-observer
      ref="formVal"
    >
      <p class="font-weight-bolder font-medium-5 mt-1">
        {{ $t('DVS Check') }}
      </p>
      <b-row>
        <b-col
          v-for="key in ['idscan_dvs_applicant_endpoint', 'idscan_dvs_validation_endpoint', 'idscan_dvs_secret_token', 'idscan_dvs_status']"
          :key="key"
          md="6"
        >
          <component
            :is="fields[key].type"
            v-model="item[key]"
            v-bind="getProps(key)"
            :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.action, ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.subject)"
          />
        </b-col>
      </b-row>
      <p class="font-weight-bolder font-medium-5 mt-1">
        {{ $t('Screening') }}
      </p>
      <b-row>
        <b-col
          v-for="key in ['idscan_screening_endpoint']"
          :key="key"
          md="6"
        >
          <component
            :is="fields[key].type"
            v-model="item[key]"
            v-bind="getProps(key)"
            :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.action, ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.subject)"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-for="key in ['idscan_screening_criminal_service', 'idscan_screening_identifraud_service', 'idscan_screening_live_token', 'idscan_screening_status']"
          :key="key"
          md="6"
        >
          <component
            :is="fields[key].type"
            v-model="item[key]"
            v-bind="getProps(key)"
            :is-editable="!$can(ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.action, ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.subject)"
          />
        </b-col>
      </b-row>
      <portal to="body-footer">
        <div class="d-flex mt-2 pb-1 justify-content-between">
          <div>
            <b-button
              v-if="!isFormChanged"
              class="cancelBtn font-medium-1 font-weight-bolder"
              variant="outline-primary"
              @click="$router.push({name: 'settings-general-integrations'})"
            >
              {{ $t('Back to List') }}
            </b-button>
            <b-button
              v-else
              class="cancelBtn font-medium-1 font-weight-bolder"
              variant="outline-primary"
              @click="loader"
            >
              <feather-icon
                icon="LCancelIcon"
                size="16"
              />
              {{ $t('Cancel') }}
            </b-button>
          </div>
          <div>
            <b-button
              v-if="$can(ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.action, ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION.subject)"
              variant="success"
              class="saveBtn font-medium-1 font-weight-bolder"
              :disabled="!isFormChanged"
              @click="submit"
            >
              {{ $t('SAVE') }}
            </b-button>
          </div>
        </div>
      </portal>
    </validation-observer>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
  <skeleton v-else />
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import skeleton from '@/views/settings/general/integrations/view/idscan/Skeleton.vue'
import { BRow, BCol, BButton } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-cycle
import { scrollToError } from '@core/utils/utils'
import BSWarningModal from '@core/components/BSWarningModal/BSWarningModal.vue'
import idScan from '@/assets/images/integrations/id.svg'

export default {
  name: 'IntegrationComponent',
  components: {
    skeleton,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    BSWarningModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    fields: {
      type: Object,
      required: true,
    },
    get: {
      type: Function,
      required: true,
    },
    set: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      onSubmit: false,
    }
  },
  computed: {
    ...mapState('settingsIntegrations', ['item', 'itemClone']),
    isFormChanged() {
      return JSON.stringify(this.item) !== JSON.stringify(this.itemClone)
    },
  },
  created() {
    this.refetchData()
  },
  methods: {
    ...mapMutations('settingsIntegrations', ['GET', 'GET_CLONE']),
    refetchData() {
      this.loading = true
      this.GET({})
      this.GET_CLONE({})
      // this.loading = false
      this.get().then(res => {
        const { data } = res.data
        this.GET(data)
        this.GET_CLONE(data)
      }).finally(() => {
        this.loading = false
      })
    },
    getProps(fieldName) {
      return {
        field: this.fields[fieldName],
        name: fieldName,
        ref: `${fieldName}_ref`,
      }
    },
    loader() {
      this.$refs.formVal.reset()
      const clone = { ...this.itemClone }
      this.GET(clone)
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.$refs.formVal.validate().then(success => {
        if (success) {
          this.onSubmit = true
          const payload = {
            idscan_dvs_applicant_endpoint: this.item.idscan_dvs_applicant_endpoint,
            idscan_dvs_validation_endpoint: this.item.idscan_dvs_validation_endpoint,
            idscan_dvs_secret_token: this.item.idscan_dvs_secret_token,
            idscan_dvs_status: this.item.idscan_dvs_status,
            idscan_screening_endpoint: this.item.idscan_screening_endpoint,
            idscan_screening_status: this.item.idscan_screening_status,
            idscan_screening_criminal_service: this.item.idscan_screening_criminal_service,
            idscan_screening_identifraud_service: this.item.idscan_screening_identifraud_service,
            idscan_screening_live_token: this.item.idscan_screening_live_token,
          }
          this.set(payload)
            .then(() => {
              this.$toast.success(this.$t('Successfully saved'))
              this.refetchData()
            })
            .catch(() => {
              scrollToError(this, this.$refs.formVal)
            }).finally(() => {
              this.onSubmit = false
            })
        }
      })
    },
  },
  setup() {
    const ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION = { action: 'change', subject: 'Integrations' }

    return {
      idScan,
      ACCESS_ABILITY_FOR_SYSTEM_INTEGRATION,
    }
  },
}
</script>
<style scoped>

</style>
