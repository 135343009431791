<template>
  <div>
    <div class="d-flex justify-content-center align-items-center align-self-md-center">
      <img
        alt="Payment Gateway"
        :src="idScan"
      >
      <h3 class="text-center font-weight-bolder mb-1 ml-1">
        {{ $t('ID Scan') }}
      </h3>
    </div>
    <p class="font-weight-bolder font-medium-5 mt-1">
      {{ $t('DVS Check') }}
    </p>
    <b-row>
      <b-col
        v-for="field in ['idscan_dvs_applicant_endpoint', 'idscan_dvs_validation_endpoint', 'idscan_dvs_secret_token', 'idscan_dvs_status']"
        :key="field"
        md="6"
      >
        <span
          class="font-weight-bolder d-block"
          style="margin-bottom: 5px; margin-left: 10px"
        >{{ fields[field].label }}</span>
        <b-skeleton
          height="35px"
          style="border-radius: 5px"
        />
      </b-col>
    </b-row>
    <p class="font-weight-bolder font-medium-5 mt-1">
      {{ $t('Screening') }}
    </p>
    <b-row>
      <b-col
        v-for="field in ['idscan_screening_endpoint']"
        :key="field"
        md="6"
      >
        <span
          class="font-weight-bolder d-block"
          style="margin-bottom: 5px; margin-left: 10px"
        >{{ fields[field].label }}</span>
        <b-skeleton
          height="35px"
          style="border-radius: 5px"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        v-for="field in ['idscan_screening_criminal_service', 'idscan_screening_identifraud_service', 'idscan_screening_live_token', 'idscan_screening_status']"
        :key="field"
        md="6"
      >
        <span
          class="font-weight-bolder d-block"
          style="margin-bottom: 5px; margin-left: 10px"
        >{{ fields[field].label }}</span>
        <b-skeleton
          height="35px"
          style="border-radius: 5px"
        />
      </b-col>
    </b-row>

  </div>
</template>
<script>
import {
  BCol, BRow, BSkeleton,
} from 'bootstrap-vue'
import config from '@/views/settings/general/integrations/view/idscan/config'
import idScan from '@/assets/images/integrations/id.svg'

export default {
  name: 'Skeleton',
  components: {
    BRow, BCol, BSkeleton,
  },

  setup() {
    const { fields } = config()

    return {
      idScan,
      fields,
    }
  },
}

</script>

<style scoped>

</style>
