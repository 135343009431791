import { render, staticRenderFns } from "./IDScan.vue?vue&type=template&id=b59039c2&scoped=true"
import script from "./IDScan.vue?vue&type=script&lang=js"
export * from "./IDScan.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b59039c2",
  null
  
)

export default component.exports