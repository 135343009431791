<template>
  <integration-component
    ref="formRef"
    :set="setIDScan"
    :get="getIDScan"
    :fields="fields"
    title="ID Scan"
  />
</template>

<script>
import { mapActions } from 'vuex'
import IntegrationComponent from '@/views/settings/general/integrations/view/idscan/IntegrationComponent.vue'
import config from './config'
import mixin from '../mixin'

export default {
  name: 'IDScan',
  components: { IntegrationComponent },
  mixins: [mixin],
  methods: {
    ...mapActions('settingsIntegrations', ['getIDScan', 'setIDScan']),
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>

<style scoped>

</style>
