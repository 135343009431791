import { L_ACTIVE_CHECK_LF_BOX, TEXT_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const fields = {
    idscan_dvs_applicant_endpoint: {
      type: TEXT_INPUT,
      label: 'DVS Applicant Endpoint',
      rules: 'required',
      placeholder: 'Type...',
    },
    idscan_dvs_validation_endpoint: {
      type: TEXT_INPUT,
      label: 'DVS Validation Endpoint',
      rules: 'required',
      placeholder: 'Type...',
    },
    idscan_dvs_secret_token: {
      type: TEXT_INPUT,
      label: 'Secret Token',
      rules: 'required',
      placeholder: 'Type...',
    },
    idscan_dvs_status: {
      type: L_ACTIVE_CHECK_LF_BOX,
      label: 'Active',
      rules: 'required',
    },

    idscan_screening_endpoint: {
      type: TEXT_INPUT,
      label: 'Screening Endpoint',
      rules: 'required',
      placeholder: 'Type...',
    },
    idscan_screening_status: {
      type: L_ACTIVE_CHECK_LF_BOX,
      label: 'Active',
      rules: 'required',
    },
    idscan_screening_criminal_service: {
      type: TEXT_INPUT,
      label: 'Screening Criminal Service',
      rules: 'required',
      placeholder: 'Type...',
    },
    idscan_screening_identifraud_service: {
      type: TEXT_INPUT,
      label: 'Screening Identifraud Service',
      rules: 'required',
      placeholder: 'Type...',
    },
    idscan_screening_live_token: {
      type: TEXT_INPUT,
      label: 'Screening Live Token',
      rules: 'required',
      placeholder: 'Type...',
    },
  }
  return {
    fields,
  }
}
